<template>
  <v-card :color="statusColor.card">
    <v-container class="pa-0">
      <v-row dense>
        <v-col cols="5"
          ><v-icon :size="icon.size" class="mr-1" :color="statusColor.icon">
            {{ icon.symbol }}
          </v-icon></v-col
        >
        <v-col cols="7">
          <v-card-title class="text-h5">
            {{ statusText.title }}
            <div class="text-overline ml-2">{{ statusText.subtitle }}</div>
          </v-card-title>
          <v-card-subtitle class="text-subtitle-2 mt-2">
            <p>{{ areaInStage(stage) }}</p>
            <span class="caption">{{ effective }}</span>
          </v-card-subtitle>
          <v-card-text>{{ stage.comment }} </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusIcon } from '@/components/requests/statusModel'
import { formattedDateTime } from '@/micro/time/date'

export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    statusOf: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters('assignments', ['areaInStage']),
    ...mapGetters('workflows', ['statusColorByStage']),
    icon() {
      return this.statusIcon(this.stage.id, this.stage.status)
    },
    statusColor() {
      return this.statusColorByStage(
        this.stage,
        this.statusOf(this.stage, this.editedItem),
        this.editedItem
      )
    },
    statusText() {
      const status = this.statusOf(this.stage, this.editedItem)
      const actionTxt =
        this.stage.id === 'auth1'
          ? 'Aprobó'
          : this.stage.id === 'auth2'
          ? 'Autorizó'
          : 'Validó'
      const title =
        status === 'approved' || status === 'approved_alert'
          ? actionTxt
          : 'Rechazó'
      const subtitle = status === 'approved_alert' ? '' : 'a tiempo'
      return { title, subtitle }
    },
    effective() {
      return formattedDateTime(this.stage.effective)
    }
  },
  methods: {
    statusIcon
  }
}
</script>
