export function statusIcon(stageId, status) {
  const res = stageId
    ? statusIcons.find(icon => icon.id === stageId && icon.status === status)
    : { defined: false }
  return res
}
export const statusIcons = [
  {
    id: 'init',
    status: 'approved',
    symbol: 'mdi-calendar-start',
    defined: false
  },
  {
    id: 'auth1',
    status: 'approved',
    symbol: 'mdi-shield-check',
    size: 28,
    defined: true,
    persistent: true
  },
  {
    id: 'auth1',
    status: 'rejected',
    symbol: 'mdi-shield-remove-outline',
    size: 28,
    defined: true,
    persistent: true
  },
  {
    id: 'auth2',
    status: 'approved',
    symbol: 'mdi-shield-check',
    size: 32,
    defined: true,
    persistent: true
  },
  {
    id: 'auth2',
    status: 'rejected',
    symbol: 'mdi-shield-remove-outline',
    size: 32,
    defined: true,
    persistent: true
  },
  {
    id: 'pass1',
    status: 'approved',
    symbol: 'mdi-qrcode-scan',
    defined: false
  },
  {
    id: 'checkin',
    status: 'approved',
    symbol: 'mdi-location-enter',
    size: 36,
    defined: true,
    persistent: false
  },
  {
    id: 'checkin',
    status: 'rejected',
    symbol: 'mdi-location-enter',
    size: 36,
    defined: true,
    persistent: false
  },
  {
    id: 'auth3',
    status: 'approved',
    symbol: 'mdi-shield-check',
    size: 36,
    defined: true,
    persistent: true
  },
  {
    id: 'auth3',
    status: 'rejected',
    symbol: 'mdi-shield-remove-outline',
    size: 36,
    defined: true,
    persistent: true
  },
  {
    id: 'pass2',
    status: 'approved',
    symbol: 'mdi-qrcode-scan',
    defined: false
  },
  {
    id: 'checkout',
    status: 'approved',
    symbol: 'mdi-location-exit',
    size: 36,
    defined: true,
    persistent: false
  },
  {
    id: 'checkout',
    status: 'rejected',
    symbol: 'mdi-location-exit',
    size: 36,
    defined: true,
    persistent: false
  }
]
