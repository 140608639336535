<template>
  <v-tooltip top v-if="icon.defined">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        :size="icon.size"
        class="mr-1"
        :color="statusColor.icon"
      >
        {{ icon.symbol }}
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusIcon } from '@/components/requests/statusModel'
import { isAuthStage } from '@/components/requests/stagesControl'

export default {
  props: {
    stage: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isCurrentStatusRejected: {
      type: Function,
      required: true
    },
    statusOf: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapGetters('workflows', ['statusColorByStage']),
    ...mapGetters('assignments', ['areaInStage']),
    status() {
      return this.statusOf(this.stage, this.item)
    },
    icon() {
      return this.statusIcon(this.stage.id, this.stage.status)
    },
    statusColor() {
      return this.statusColorByStage(this.stage, this.status, this.item)
    },
    tooltip() {
      return this.applyStatusMsgsTpl(this.status)
    }
  },

  methods: {
    statusIcon,

    applyStatusMsgsTpl(status) {
      const area = this.areaInStage(this.stage)
      const key = isAuthStage(this.stage) ? 'auth' : this.stage.id
      const stageType = this.statusMsgs[key]
      const msg = stageType[status]
      return this.isCurrentStatusRejected(this.item, this.$store)
        ? 'Rechazado previamente'
        : stageType.requestor
        ? msg
        : `${area} ${msg}`
    }
  },
  created() {
    this.statusMsgs = {
      init: {
        approved: '',
        approved_alert: '',
        rejected: '',
        pending: '',
        pending_alert: '',
        requestor: false
      },
      auth: {
        approved: 'aprobó',
        approved_alert: 'aprobó',
        rejected: 'rechazó',
        pending: 'pendiente',
        pending_alert: 'sin atender oportunamente',
        requestor: false
      },
      checkin: {
        approved: 'Llegó a tiempo',
        approved_alert: 'Llegó tarde',
        rejected: 'No llegó',
        pending: 'En camino',
        pending_alert: 'Ya viene tarde',
        requestor: true
      },
      checkout: {
        approved: 'Salió a tiempo',
        approved_alert: 'Salió tarde',
        rejected: 'El usuario no registró su salida',
        pending: 'En sitio',
        pending_alert: 'El usuario ya se pasó del tiempo programado',
        requestor: true
      }
    }
  }
}
</script>
