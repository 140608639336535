<template>
  <v-card :color="statusColor.card" :dark="dark">
    <v-container>
      <v-row dense>
        <v-col cols="3"
          ><v-icon :size="icon.size" class="mr-1" :color="statusColor.icon">
            {{ icon.symbol }}
          </v-icon></v-col
        >
        <v-col cols="9">
          <v-card-title class="headline">
            {{ subtitle }}
          </v-card-title>
          <v-card-subtitle class="mt-16"
            ><v-text-field
              label="Comentarios (opcional)"
              class="text-subtitle-1 text--primary"
              v-model="comment"
            ></v-text-field
          ></v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            {{ roleArea }}
            <v-btn
              class="ml-2"
              outlined
              rounded
              small
              :loading="saving"
              :disabled="saving"
              @click="save(comment)"
            >
              {{ acceptBtn }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { nextStatusOf } from '@/components/requests/statusControl'
import { statusIcon } from '@/components/requests/statusModel'
import { currentStageOf } from '@/components/requests/stagesControl'

export default {
  data() {
    return {
      comment: ''
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('workflows', [
      'currentNextStageId',
      'currentNextStatusColor'
    ]),
    ...mapGetters('authsWorkflow', ['authTextsById']),

    dark() {
      return this.action !== 'approve'
    },

    headline() {
      return this.actionContents(this.editedItem, 'headline')
    },

    requestorName() {
      return `para ${this.editedItem.party.name}`
    },

    subtitle() {
      return this.actionContents(this.editedItem, 'subtitle')[this.action]
    },

    roleArea() {
      return this.actionContents(this.editedItem, 'role_descn')
    },

    acceptBtn() {
      return this.actionContents(this.editedItem, 'btn')[this.action]
    },

    // statusColor() {
    //   return this.nextStatusColor(
    //     this.stage,
    //     this.action === 'approve' ? 'approved' : 'rejected',
    //     this.editedItem
    //   )
    // },

    // @todo check if the implementation must use outlined in place of current
    statusColor() {
      const status = this.action === 'approve' ? 'approved' : 'rejected'
      return this.currentNextStatusColor(status, this.editedItem)
    },

    status() {
      return this.nextStatusOf(this.stage, this.editedItem)
    },

    icon() {
      return this.statusIcon(
        this.currentNextStageId(this.editedItem),
        'approved'
      )
    },

    actionContents() {
      return (item, content) => {
        const nextId = this.currentNextStageId(item)
        return nextId ? this.authTextsById(nextId, this.type)[content] : ''
      }
    }
  },
  methods: {
    statusIcon,
    currentStageOf,
    nextStatusOf
  }
}
</script>
