<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    :transition="dialogTransition"
    v-model="dialog"
    max-width="500px"
  >
    <template>
      <v-card class="mx-auto">
        <v-toolbar color="transparent">
          <v-toolbar-title class="identity--text">{{
            toolbarTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <!-- Init Workflow / Request -->
            <v-col
              cols="12"
              :key="stage.position"
              v-for="stage in stagesOrderedByPosition"
            >
              <request-card
                v-if="isAccessRequest(type) && isInitStage(stage)"
                :editedItem="editedItem"
              ></request-card>

              <doc-card
                class="mt-3"
                v-if="isSomeDocRequirementActivity(type) && isInitStage(stage)"
                :editedItem="editedItem"
                :requirementId="editedItem.resource.requirementId"
                :resourceId="editedItem.resource.id"
                :avatar="managedImg(editedItem.resource.avatar)"
                :link="editedItem.resource.link"
                :hrs="editedItem.resource.hrs"
                :name="editedItem.resource.name"
                :stats="statsById(editedItem.resource.requirementId)"
                :universe="getStatsUniverse"
              ></doc-card>

              <auth-card
                class="mt-3"
                v-if="isAuthStage(stage)"
                :editedItem="editedItem"
                :stage="stage"
                :statusOf="statusOf(editedItem)"
              ></auth-card>

              <action-card
                class="mt-3"
                v-if="isVisibleAction(stage)"
                :type="type"
                :editedItem="editedItem"
                :stage="stage"
                :statusOf="statusOf(editedItem)"
              ></action-card>

              <pass-card
                class="mt-3"
                v-if="isRequestRoleActivity(type) && isPassStage(stage)"
                :editedItem="editedItem"
                :stage="stage"
                :statusOf="statusOf(editedItem)"
                :action="actionType"
              ></pass-card>

              <pre-assessment-card
                class="mt-3"
                v-if="needsPreAssessment(stage, editedItem)"
                :type="type"
                :editedItem="editedItem"
                :stage="stage"
                :action="actionType"
                :save="save"
                :saving="saving"
              ></pre-assessment-card>

              <assessment-card
                class="mt-3"
                v-if="needsAssessment(stage, editedItem)"
                :type="type"
                :editedItem="editedItem"
                :stage="stage"
                :action="actionType"
                :save="save"
                :saving="saving"
              ></assessment-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  isAuthStage,
  isInitStage,
  isPassStage,
  isUnderExpiration
} from '@/components/requests/stagesControl'
import {
  isAccessRequest,
  isRequestRoleActivity,
  isSomeDocRequirementActivity,
  isAuthDocRoleActivity,
  isAuthRoleActivity
} from '@/components/requests/activityControl'
import { isPhone } from '@/micro/display'
import RequestCard from '@/components/requests/views/RequestCard'
import DocCard from '@/components/requests/views/DocCard'
import AuthCard from '@/components/requests/views/AuthCard'
import ActionCard from '@/components/requests/views/ActionCard'
import PassCard from '@/components/requests/views/PassCard'
import PreAssessmentCard from '@/components/requests/views/PreAssessmentCard'
import AssessmentCard from '@/components/requests/views/AssessmentCard'
import { statusOf } from '@/components/requests/statusControl'
import { managedImg } from '@/micro/img'

export default {
  components: {
    RequestCard,
    DocCard,
    AuthCard,
    ActionCard,
    PassCard,
    PreAssessmentCard,
    AssessmentCard
  },

  data: () => ({
    saving: false,
    sortAsc: true,
    actionType: '',
    dialog: false,
    editedIndex: -1,
    defaultItem: {
      id: null,
      uid: null,
      topic: '',
      created_at: null,
      party: {
        name: '',
        area: '',
        l: '',
        o: '',
        info: null
      },
      resource: {
        schedule: '',
        duration: '',
        time: '',
        schedule_start: '',
        schedule_end: '',
        site: '',
        terms: false,
        motivation: ''
      },
      stats: {
        timeQty: 0,
        areaQty: 0
      },
      workflow: {}
    },
    editedItem: {
      id: null,
      uid: null,
      topic: '',
      created_at: null,
      party: {
        name: '',
        area: '',
        l: '',
        o: '',
        info: null
      },
      resource: {
        schedule: '',
        duration: '',
        time: '',
        schedule_start: '',
        schedule_end: '',
        site: '',
        terms: false,
        motivation: ''
      },
      stats: {
        timeQty: 0,
        areaQty: 0
      },
      workflow: {}
    }
  }),

  props: {
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  computed: {
    ...mapGetters('sites', ['siteById']),
    ...mapGetters('workflows', ['willNextStageAuth', 'isStageCurrent']),
    ...mapGetters('requirements', ['statsById', 'getStatsUniverse']),
    ...mapGetters('workflows', [
      'willNextStageAuth1',
      'willNextStageAuth2',
      'willNextStageAuth3'
    ]),
    ...mapGetters('user', [
      'amISupervisor',
      'amIHeadOfSecurity',
      'amIConsultant'
    ]),

    stagesOrderedByPosition() {
      const raw = this.editedItem.workflow.stages
        ? [...this.editedItem.workflow.stages]
        : []
      return raw.sort((a, b) => a.position - b.position)
    },

    needsPreAssessment() {
      return (stage, item) => {
        const canAuth =
          this.isAuthRoleActivity(this.type) &&
          this.accomplishCommonAssessment(stage, item) &&
          stage.status === 'approved'
        const isAuth1 =
          this.amISupervisor && this.willNextStageAuth1(item) && canAuth
        const isAuth2 =
          this.amIHeadOfSecurity && this.willNextStageAuth2(item) && canAuth
        const isAuth3 =
          this.amIConsultant && this.willNextStageAuth3(item) && canAuth
        return isAuth1 || isAuth2 || isAuth3
      }
    },

    needsAssessment() {
      return (stage, item) =>
        this.isAuthDocRoleActivity(this.type) &&
        this.willNextStageAuth(item) &&
        this.accomplishCommonAssessment(stage, item)
    },

    accomplishCommonAssessment() {
      return (stage, item) =>
        this.isStageCurrent(stage, item) && this.isUnderExpiration(stage, item)
    },

    isVisibleAction() {
      return stage =>
        !isAuthStage(stage) && !isInitStage(stage) && !isPassStage(stage)
    },

    toolbarTitle() {
      return this.editedItem.id === null ||
        this.editedItem.workflow.stages.length === 0
        ? ''
        : this.willNextStageAuth(this.editedItem)
        ? 'Revisión de la solicitud'
        : 'Estado de la solicitud'
    },

    currentAuths() {
      return item =>
        item.workflow.stages.filter(stage => this.isAuthStage(stage))
    },

    isPhone() {
      return isPhone(this.$vuetify)
    },

    dialogTransition() {
      return this.isPhone ? 'dialog-bottom-transition' : ''
    }
  },

  methods: {
    ...mapActions('requests', ['sendStage']),

    statusOf,

    isAuthStage,

    isInitStage,

    isPassStage,

    isRequestRoleActivity,

    isAccessRequest,

    isSomeDocRequirementActivity,

    managedImg,

    isUnderExpiration,

    isAuthDocRoleActivity,

    isAuthRoleActivity,

    open(item, status) {
      this.actionType = status
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    save(comment) {
      this.saving = true
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem)
      } else {
        this.items.push(this.editedItem)
      }
      this.sendStage({
        item: this.editedItem,
        action: this.actionType,
        comment
      })
        .then(() => {
          this.close()
          this.saving = false
        })
        .catch(error => {
          this.saving = false
          console.log('Can not send the stage.', error)
        })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    isAuthDialog() {
      return this.actionType === 'to_auth'
    }
  }
}
</script>
