<template>
  <v-card flat dense>
    <template>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :sort-by="sortBy"
        :sort-desc="false"
        class="elevation-1"
        :search="search"
        locale="es-MX"
        :group-by="groupBy"
        show-group-by
        :hide-default-header="!isPhone"
        :loading="loading"
        loading-text="Cargando... por favor espere."
      >
        <!-- Table Top -->
        <template v-slot:top>
          <v-toolbar color="blue-grey lighten-5" class="mb-5" elevation="1">
            <v-text-field
              v-model="search"
              label="Buscar"
              append-icon="mdi-magnify"
              single-line
              hide-details
              class="mx-2"
            ></v-text-field>
            <v-switch
              class="mt-4 ml-6 mr-3 red--text"
              dense
              flat
              v-model="onlyPendingSelection"
              label="Pendientes"
            ></v-switch>
          </v-toolbar>

          <!-- Auth Dialog -->
          <stages-dialog ref="dialog" :type="type" :items="items">
          </stages-dialog>
        </template>

        <!-- Header -->
        <template v-if="!isPhone" v-slot:header="props">
          <thead>
            <tr>
              <template v-for="header in props.props.headers">
                <th :key="header.value">
                  <span
                    @click.stop="props.on.sort(header.value)"
                    :class="
                      sortedTableFieldStyle(header.value, props.props.options)
                    "
                    >{{ header.text }}</span
                  >
                  <v-btn
                    style="width: 20px"
                    @click.stop="props.on.sort(header.value)"
                    icon
                  >
                    <v-icon
                      small
                      color="black"
                      v-if="
                        isTableSortedByValue(
                          header.value,
                          props.props.options
                        ) < 0
                      "
                      >mdi-arrow-down</v-icon
                    >
                    <v-icon
                      small
                      color="black"
                      v-else-if="
                        isTableSortedByValue(
                          header.value,
                          props.props.options
                        ) > 0
                      "
                      >mdi-arrow-up</v-icon
                    >
                  </v-btn>
                  <v-btn
                    style="width: 20px"
                    @click.stop="props.on.group(header.value)"
                    icon
                  >
                    <v-icon medium>mdi-select-group</v-icon>
                  </v-btn>
                </th>
              </template>
            </tr>
          </thead>
        </template>

        <!-- Group.header -->
        <template
          v-slot:group.header="{
            group,
            groupBy,
            items,
            remove,
            toggle,
            isOpen
          }"
        >
          <td :colspan="headers.length" class="text-center">
            <v-container class="blue-grey lighten-5" fluid>
              <v-row no-gutters>
                <v-col align-self="center">
                  <v-card class="blue-grey lighten-5" flat @click="toggle()">
                    <span class="text-subtitle-2 pl-1">{{
                      tableGroup(groupBy, items).title
                    }}</span>
                    <v-icon v-if="isOpen" medium>mdi-chevron-up</v-icon>
                    <v-icon v-else medium>mdi-chevron-down</v-icon>
                  </v-card>
                </v-col>
                <v-col align-self="center">
                  <v-card class="blue-grey lighten-5" flat>
                    <v-badge
                      color="grey lighten-2 black--text"
                      :content="tableGroup(groupBy, items).count"
                    >
                      <v-chip
                        color="identity white--text"
                        class="text-subtitle-2 text-sm-h6 pa-4"
                        :class="scheduleHeaderStyle"
                      >
                        {{ formattedGroupValue(group) }}
                      </v-chip>
                    </v-badge>
                  </v-card>
                </v-col>
                <v-col align-self="center" class="text-center">
                  <v-card class="blue-grey lighten-5" flat @click="remove()">
                    <v-icon medium color="grey">mdi-close-circle</v-icon>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>

        <!-- Resource/Date created at -->
        <template v-slot:item.created_at="{ item }">
          <div class="mr-8">
            <span class="text-subtitle-2">{{
              longFormattedDate(item.created_at)
            }}</span>
          </div>
        </template>

        <!-- Resource/To -->
        <template v-slot:item.partyName="{ item }">
          <div class="mr-8">
            <span class="text-h4 text-sm-h6">{{ item.partyName }}</span>
          </div>
        </template>

        <!-- Resource/Area -->
        <template v-slot:item.partyArea="{ item }">
          <div class="mr-8">
            <span
              v-if="isRequestRoleActivity(type)"
              class="text-h5 text-sm-overline font-weight-medium"
              :class="`${colorByRelationship(item)}--text`"
              >{{ item.partyArea }}</span
            >

            <v-badge
              v-else
              :color="semaphoreColorByQty(areaQty(item))"
              :content="areaQty(item)"
              class="mr-3 font-weight-black"
            >
              <div
                width="140px"
                class="text-h5 text-sm-overline font-weight-medium"
                :class="`${colorByRelationship(item)}--text`"
              >
                {{ item.partyArea }}
              </div>
            </v-badge>
          </div>
        </template>

        <!-- BEGIN Access Resource -->
        <!-- Resource/Site -->
        <template v-if="isAccessRequest" v-slot:item.siteName="{ item }">
          <div class="mr-8">
            <span
              :class="siteStyle"
              class="text-h4 av-text-h4 text-sm-subtitle-2 font-weight-medium"
              >{{ item.siteName }}</span
            >
          </div>
        </template>

        <!-- Resource/Schedule date -->
        <template
          v-if="isAccessRequest"
          v-slot:item.resource.schedule_start="{ item }"
        >
          <div class="mr-8">
            <span class="text-h5 text-sm-h6">{{
              shortFormattedDate(item.resource.schedule_start)
            }}</span>
          </div>
        </template>

        <!-- Resource/Time -->
        <template v-if="isAccessRequest" v-slot:item.resource.time="{ item }">
          <v-badge
            :color="semaphoreColorByTimeQty(timeQty(item))"
            :content="timeQty(item)"
            class="mr-8 font-weight-black"
          >
            <v-chip color="brown lighten-5" class="text-h5 text-sm-h6 pa-4">
              {{ item.resource.time }}
            </v-chip>
          </v-badge>
        </template>
        <!-- END Access Resource -->

        <!-- BEGIN Doc Requirement -->
        <!-- Description -->
        <template v-if="isDocRequirement" v-slot:item.resource.name="{ item }">
          <div class="mr-8">
            <span class="text-h5 text-sm-subtitle-2 font-weight-dark">{{
              item.resource.name
            }}</span>
          </div>
        </template>

        <!-- Attrs -->
        <template v-if="isDocRequirement" v-slot:item.resource.size="{ item }">
          <div class="mr-8">
            <span class="text-h5 text-sm-subtitle-2 font-weight-dark"
              >{{ (item.resource.size / (1024 * 1024)).toFixed(2) }} Mb</span
            >
          </div>
        </template>
        <!-- END Doc Requirement -->

        <!-- Status Icon -->
        <template v-slot:item.workflow.stages="{ item }">
          <div v-if="item.workflow.stages.length > 0" class="mr-8 mx-sm-auto">
            <div>
              <status-icon
                v-for="stage in lastNFilter(item, 2)"
                :key="stage.position"
                :stage="stage"
                :item="item"
                :isCurrentStatusRejected="isCurrentStatusRejectedOf(item)"
                :statusOf="statusOf(item)"
              ></status-icon>
              <next-status-icon
                v-if="!isCurrentEndStage(item)"
                :stage="currentStageOf(item.workflow.stages)"
                :item="item"
                :isCurrentStatusRejected="isCurrentStatusRejectedOf(item)"
              ></next-status-icon>
            </div>
          </div>
        </template>

        <!-- Auth Btns Actions -->
        <template v-slot:item.activity="{ item }">
          <div v-if="item.workflow.stages.length > 0" class="my-auto">
            <next-stage-btns
              :type="type"
              :item="item"
              :dialog="$refs.dialog"
              :currentStatus="currentStatusOf(item)"
            ></next-stage-btns>
          </div>
        </template>
      </v-data-table>
    </template>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import StagesDialog from '@/components/requests/views/StagesDialog'
import NextStageBtns from '@/components/requests/table/NextStageBtns'
import StatusIcon from '@/components/requests/table/StatusIcon'
import NextStatusIcon from '@/components/requests/table/NextStatusIcon'

import {
  isValidDate,
  longFormattedDate,
  shortFormattedDate
} from '@/micro/time/date'
import {
  isAccessRequest,
  isDocRequirement,
  isRequestRoleActivity
} from '@/components/requests/activityControl'
import {
  currentStageOf,
  passStageCount
} from '@/components/requests/stagesControl'
import { isPhone } from '@/micro/display'
import {
  isCurrentStatusRejectedOf,
  currentStatusOf
} from '@/components/requests/statusControl'
import { statusOf } from '@/components/requests/statusControl'
import {
  getStagesFilter,
  getScheduleFilter
} from '@/components/requests/table/filters'
import {
  semaphoreColorByQty,
  semaphoreColorByTimeQty
} from '@/components/requests/table/semaphores'
import { headersBy } from '@/components/requests/table/headers'
import { listen2DataTable } from '@/components/requests/table/data'
import { resolvedAreaName } from '@/micro/text'

export default {
  components: {
    StagesDialog,
    StatusIcon,
    NextStatusIcon,
    NextStageBtns
  },

  data: () => ({
    dataLoaded: false,
    search: '',
    onlyPendingSelection: true,
    headers: []
  }),

  props: {
    type: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('requests', ['loading']),
    ...mapGetters('user', ['relationship', 'ou', 'areas', 'roles', 'uid']),
    ...mapGetters('sites', ['siteById']),
    ...mapGetters('workflows', ['isCurrentEndStage']),
    ...mapGetters('requests', ['requestsByType']),

    resolvedHeadOfArea() {
      return this.areas.length > 0 ? this.areas[0].aid : ''
    },

    items() {
      return this.requestsByType(this.type).map(item => {
        return {
          ...item,
          ...{ siteName: this.siteById(item.resource.site).name },
          ...{ partyName: item.party.name },
          ...{ partyArea: this.areaName(item.party.aname) }
        }
      })
    },

    timeQty() {
      return item => (item.stats ? item.stats.timeQty : 0)
    },

    areaQty() {
      return item => (item.stats ? item.stats.areaQty : 0)
    },

    isAccessRequest() {
      return isAccessRequest(this.type)
    },

    isDocRequirement() {
      return isDocRequirement(this.type)
    },

    isPhone() {
      return isPhone(this.$vuetify)
    },

    // Table
    tableGroup() {
      return (groupBy, items) => {
        const groupKey = groupBy[0]
        const header = this.headers.find(header => header.value === groupKey)
        const res = {
          title: header.text || '',
          count: items.length || 0
        }
        return res
      }
    },

    // Styles
    scheduleHeaderStyle() {
      return this.isPhone ? 'font-weight-bold' : ''
    },

    siteStyle() {
      return this.isRequestRoleActivity(this.type)
        ? ''
        : 'grey--text text--darken-1'
    },

    sortBy() {
      if (this.isAccessRequest) return 'resource.schedule_start'
      else return 'created_at'
    },

    groupBy() {
      if (this.isAccessRequest) return 'resource.schedule_start'
      else return 'created_at'
    },

    areaName() {
      return name => resolvedAreaName(name)
    }
  },

  methods: {
    statusOf,

    // Date formats
    isValidDate,

    shortFormattedDate,

    longFormattedDate,

    // Auth control
    isRequestRoleActivity,

    currentStageOf,

    isCurrentStatusRejectedOf,

    currentStatusOf,

    semaphoreColorByQty,

    semaphoreColorByTimeQty,

    lastNFilter(item, n) {
      const stages = item.workflow.stages
      const passCount = passStageCount(item)
      const max = n + passCount
      return this.isCurrentEndStage(item)
        ? stages.slice(-max - 1) // Display last -n - 1
        : stages.length - 1 > max
        ? stages.slice(-max) // Display last n
        : stages // Displan 0..n
    },

    initTableData() {
      let dataHeaders = headersBy(this.type)
      // @todo begin registerFilters(dataHeaders)
      const stagesFilter = getStagesFilter(dataHeaders, this)
      dataHeaders.splice(stagesFilter.index, 1, stagesFilter.obj)
      if (this.isAccessRequest) {
        const scheduleFilter = getScheduleFilter(dataHeaders, this)
        dataHeaders.splice(scheduleFilter.index, 1, scheduleFilter.obj)
      }
      // @todo end registerFilters(dataHeaders)
      this.headers = dataHeaders
      this.unsubscribe = listen2DataTable(
        this.type,
        this.relationship,
        this.ou,
        this.resolvedHeadOfArea,
        this.roles,
        this.uid,
        this.$store
      )
    },

    // Table group
    formattedGroupValue(value) {
      return this.isValidDate(value)
        ? this.shortFormattedDate(value)
        : this.authStatusDescriptions[value]
    },

    // Table sorting
    btnStatusLabel(reqType, stage) {
      return this.btnStatusLabels[stage][reqType]
    },

    isTableSortedByValue(value, options) {
      if (options.sortBy[0] === value && options.sortDesc[0]) return -1
      else if (options.sortBy[0] !== value) return 0
      else return 1
    },

    sortedTableFieldStyle(value, options) {
      const sorted = this.isTableSortedByValue(value, options)
      if (sorted !== 0) return 'font-weight-black'
    },

    colorByRelationship(item) {
      return item.party.l === 'b' ? 'blue' : ''
    }
  },

  created() {
    this.authStatusDescriptions = {
      approved: 'Aceptados',
      pending: 'Pendientes',
      pending_alert: 'Pendientes y demorados',
      rejected: 'Declinados'
    }

    this.unsubscribe = () => {}
    this.initTableData()
    // this.initTableData().then(() => {
    //   this.dataLoaded = true
    // })
  },

  beforeDestroy() {
    this.unsubscribe()
  }
}
</script>

<style lang="scss">
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  display: block !important;
  border-bottom: 15px solid !important;
}
.v-data-footer {
  flex-wrap: nowrap;
}
.v-application .text-h4 {
  letter-spacing: 6px !important;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: black;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0px;
}
</style>
