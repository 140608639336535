<template>
  <div>
    <!-- Auth -->
    <div
      v-if="doesRequireAuth(item)"
      class="d-flex justify-center mr-4 mb-4 mb-sm-0"
    >
      <span>Autorizar: </span>
      <!-- Rejection -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            fab
            @click="dialog.open(item, 'reject')"
          >
            No <v-icon color="red accent-3">mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>Rechazar</span>
      </v-tooltip>

      <!-- Approval -->
      <div class="d-flex justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              fab
              @click="dialog.open(item, 'approve')"
            >
              Si
              <v-icon color="light-green darken-1"
                >mdi-check-circle-outline</v-icon
              >
            </v-btn>
          </template>
          <span>Aceptar</span>
        </v-tooltip>
      </div>
    </div>

    <!-- Default -->
    <div v-else class="d-flex justify-center mr-4">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :rounded="true"
            class="mx-2"
            @click="dialog.open(item, status(item))"
          >
            {{ label }}
            <v-icon :color="statusColor" class="ml-2">
              {{ icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isAuthRoleActivity } from '@/components/requests/activityControl'
import { nextActionTexts } from '@/components/requests/actionModel'
import { currentStageOf, isUnderExpiration } from '../stagesControl'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Object,
      required: false
    },
    currentStatus: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapGetters('workflows', [
      'willNextStageAuth1',
      'willNextStageAuth2',
      'willNextStageAuth3'
    ]),
    ...mapGetters('user', [
      'amISupervisor',
      'amIHeadOfSecurity',
      'amIConsultant'
    ]),

    // ...mapGetters('workflows', ['statusColorByStage']),

    statusColor() {
      const stage = currentStageOf(this.item.workflow.stages)
      return stage.status === 'approved'
        ? 'light-green darken-1'
        : 'red accent-3'
    },

    doesRequireAuth() {
      return item => {
        const stage = currentStageOf(item.workflow.stages)
        const canAuth =
          isAuthRoleActivity(this.type) &&
          this.isUnderExpiration(stage, item) &&
          stage.status === 'approved'
        const isAuth1 =
          this.amISupervisor && this.willNextStageAuth1(item) && canAuth
        const isAuth2 =
          this.amIHeadOfSecurity && this.willNextStageAuth2(item) && canAuth
        const isAuth3 =
          this.amIConsultant && this.willNextStageAuth3(item) && canAuth
        return isAuth1 || isAuth2 || isAuth3
      }
    },

    label() {
      return this.text(this.type, this.status(this.item)).label
    },

    icon() {
      return this.text(this.type, this.status(this.item)).icon
    },

    tooltip() {
      return this.text(this.type, this.status(this.item)).tooltip
    },

    status() {
      return item => this.currentStatus(item, this.$store)
    },

    text() {
      return (type, status) => nextActionTexts(type, status)
    }
  },

  methods: {
    isUnderExpiration
  }
}
</script>
