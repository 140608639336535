<template>
  <v-card color="brown lighten-5">
    <v-container>
      <v-row dense>
        <v-col cols="3"
          ><v-icon :size="icon.size" class="mr-1" color="brown darken-4">
            mdi-file-star
          </v-icon></v-col
        >
        <v-col cols="9">
          <v-card-title class="headline">
            {{ subtitle }}
            <p>{{ requestorName }}</p>
          </v-card-title>
          <v-card-subtitle class="mt-16"
            ><v-text-field
              label="Comentarios (opcional)"
              class="text-subtitle-1 text--primary"
            ></v-text-field
          ></v-card-subtitle>
          <v-card-actions>
            <v-btn
              class="ml-2 text-dark"
              rounded
              small
              color="pink accent-3"
              dark
              :loading="saving"
              :disabled="saving"
              @click="save"
            >
              {{ rejectBtn }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ml-2 text-dark"
              rounded
              small
              color="green darken-1"
              dark
              :loading="saving"
              :disabled="saving"
              @click="save"
            >
              {{ acceptBtn }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { nextStatusOf } from '@/components/requests/statusControl'
import { statusIcon } from '@/components/requests/statusModel'
import { currentStageOf } from '@/components/requests/stagesControl'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('workflows', ['currentNextStageId']),
    ...mapGetters('authsWorkflow', ['authTextsById']),

    dark() {
      return this.action !== 'approve'
    },

    headline() {
      return this.actionContents(this.editedItem, 'headline')
    },

    requestorName() {
      return `para ${this.editedItem.party.name}`
    },

    subtitle() {
      return this.actionContents(this.editedItem, 'subtitle')[this.action]
    },

    roleArea() {
      return this.actionContents(this.editedItem, 'role_descn')
    },

    acceptBtn() {
      return this.actionContents(this.editedItem, 'btn')['approve']
    },

    rejectBtn() {
      return this.actionContents(this.editedItem, 'btn')['reject']
    },

    // statusColor() {
    //   return this.nextStatusColor(
    //     this.stage,
    //     this.action === 'approve' ? 'approved' : 'rejected',
    //     this.editedItem
    //   )
    // },

    status() {
      return this.nextStatusOf(this.stage, this.editedItem)
    },

    icon() {
      return this.statusIcon(
        this.currentNextStageId(this.editedItem),
        'approved'
      )
    },

    actionContents() {
      return (item, content) => {
        const nextId = this.currentNextStageId(item)
        return nextId ? this.authTextsById(nextId, this.type)[content] : ''
      }
    }
  },
  methods: {
    statusIcon,
    currentStageOf,
    nextStatusOf
  }
}
</script>
