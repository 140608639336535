<template>
  <v-card class="mx-auto" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4 headline identity--text">
          {{ passiveSiteNameById(editedItem.site) }}
        </div>
        <v-list-item-title class="headline mb-1">
          {{ editedItem.party.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{
          editedItem.resource.motivation
        }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar outlined size="100" color="grey">
        <v-img :src="passiveSiteImgById(editedItem.resource.site)"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-text>
      <v-chip-group column>
        <v-chip outlined class="text-subtitle-1 font-weight-bold mt-2"
          >{{ passiveScheduleStart(editedItem.resource.schedule_start) }}
          {{ passiveTime(editedItem.resource.time) }}</v-chip
        >
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { managedImg } from '@/micro/img'
import { longFormattedDate } from '@/micro/time/date'

export default {
  props: {
    editedItem: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('sites', ['siteById']),

    passiveSiteNameById() {
      return siteId => {
        const site = this.siteById(siteId)
        return site ? site.name : ''
      }
    },

    passiveSiteImgById() {
      return siteId => {
        const site = this.siteById(siteId)
        return site ? this.managedImg(site.avatar) : ''
      }
    },

    passiveScheduleStart() {
      return schedule => (schedule ? this.longFormattedDate(schedule) : '')
    },

    passiveTime() {
      return time => time || ''
    }
  },

  methods: {
    longFormattedDate,
    managedImg
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-card {
  background-color: #ffffff !important;
  border-left: 45px solid $identity !important;
}
.outlined {
  border: 3px solid $identity;
  border-radius: 50%;
  height: 56px;
  width: 56px;
}
</style>
