<template>
  <v-tooltip top v-if="icon.defined">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        :size="icon.size"
        class="mr-1"
        :color="statusColor.icon"
      >
        {{ icon.symbol }}
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusIcon } from '@/components/requests/statusModel'
import { nextStatusOf } from '@/components/requests/statusControl'

export default {
  props: {
    stage: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isCurrentStatusRejected: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapGetters('assignments', ['areaByRoleId']),
    ...mapGetters('workflows', [
      'currentNextStageId',
      'currentNextStageDescription',
      'willNextStageAuth',
      'statusColorByStage'
    ]),

    ...mapGetters('assignments', ['areaInStage']),

    statusColor() {
      return this.statusColorByStage(this.stage, this.status, this.item)
    },

    status() {
      return this.nextStatusOf(this.stage, this.item)
    },

    icon() {
      return this.statusIcon(this.currentNextStageId(this.item), 'approved')
    },

    tooltip() {
      return this.applyStatusMsgsTpl(this.status)
    }
  },

  methods: {
    nextStatusOf,
    statusIcon,

    applyStatusMsgsTpl(status) {
      const nextDescn = this.currentNextStageDescription(this.item)
      const area = this.areaByRoleId(nextDescn.role)
      const willAuth = this.willNextStageAuth(this.item)
      const key = willAuth ? 'auth' : nextDescn.id
      const stageType = this.nextStatusMsgs[key]
      const msg = stageType[status]
      return this.isCurrentStatusRejected(this.item, this.$store)
        ? 'Rechazado previamente'
        : stageType.requestor
        ? msg
        : `${area} ${msg}`
    }
  },
  created() {
    this.nextStatusMsgs = {
      init: {
        approved: '',
        approved_alert: '',
        rejected: '',
        pending: '',
        pending_alert: '',
        requestor: false
      },
      auth: {
        approved: 'Por autorizar',
        approved_alert: 'Autorización demorada',
        rejected: 'Por rechazar',
        pending: 'Pendiente',
        pending_alert: 'Autorización demorada',
        requestor: false
      },
      checkin: {
        approved: 'Por llegar',
        approved_alert: 'Llegada demorada',
        rejected: 'Por no recibir',
        pending: 'Por llegar',
        pending_alert: 'Ingreso demorado',
        requestor: true
      },
      checkout: {
        approved: 'Por salir',
        approved_alert: 'Salida demorada',
        rejected: 'No ha registrado su salida',
        pending: 'En sitio',
        pending_alert: 'Salida demorada',
        requestor: true
      }
    }
  }
}
</script>
