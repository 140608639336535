<template>
  <v-card>
    <v-toolbar color="grey darken-4" dark outlined>
      <v-spacer></v-spacer>
      <v-toolbar-title
        ><v-btn rounded outlined class="mt-1 pa-4 av-btn" to="Request"
          ><v-icon class="pr-1" color="identity">mdi-plus-circle</v-icon> Nueva
          solicitud
        </v-btn></v-toolbar-title
      >
      <v-spacer></v-spacer>

      <template v-if="amISomeReviewer" v-slot:extension>
        <v-tabs icons-and-text show-arrows v-model="tab" grow>
          <v-tabs-slider class="slider-border" color="identity"></v-tabs-slider>
          <v-tab v-for="(item, i) in items" :key="i">
            {{ item.title }}
            <v-icon color="identity">{{ item.icon }}</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab" class="mt-1">
      <v-tab-item key="1"><requests-table :type="'request'"/></v-tab-item>
      <v-tab-item key="2"><requests-table :type="'auth'"/></v-tab-item>
      <!-- <v-tab-item key="3"><requests-table :type="'auth_doc'"/></v-tab-item> -->
    </v-tabs-items>
  </v-card>
</template>

<script>
import RequestsTable from '@/components/requests/table/RequestsTable'
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/messaging'
import messagingConfig from '@/services/fbFCMConfig'
import { sendWebPushToken } from '@/components/messaging/fbApi'

export default {
  data: () => ({
    tab: null,
    items: [
      { title: 'Solicitudes', icon: 'mdi-calendar-multiple' },
      { title: 'Autorizaciones', icon: 'mdi-shield-check' }
      // { title: 'Documentos', icon: 'mdi-file-document-multiple' }
    ]
  }),
  computed: mapGetters('user', ['amISomeReviewer']),
  components: {
    RequestsTable
  },
  created() {
    const messaging = firebase.messaging()
    messaging
      .getToken({ vapidKey: messagingConfig.vapidKey })
      .then(async currentToken => {
        if (currentToken) {
          try {
            await sendWebPushToken(currentToken)
          } catch (error) {
            console.log('The current token cound not be sent it.')
          }
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          )
          // ...
        }
        console.log('messaging/token', currentToken)
      })
      .catch(error => console.log('messaging/error', error))

    messaging.onMessage(payload => console.log('onMessage(payload)', payload))
  },
  mounted() {
    this.tab = this.amISomeReviewer ? 1 : 0
  }
}
</script>

<style lang="scss">
.v-row-group__header .mdi-close,
.v-row-group__header .mdi-minus {
  font-size: 12px !important;
}
.v-tab {
  padding-bottom: 8px;
  height: 45px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.5px;
  // width: 145px !important;
}
.av-btn {
  height: 45px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.5px;
}
.v-tab:before {
  background-color: transparent;
}
.v-tabs-slider-wrapper {
  height: 3px !important;
}
</style>
