var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.doesRequireAuth(_vm.item))?_c('div',{staticClass:"d-flex justify-center mr-4 mb-4 mb-sm-0"},[_c('span',[_vm._v("Autorizar: ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":""},on:{"click":function($event){return _vm.dialog.open(_vm.item, 'reject')}}},'v-btn',attrs,false),on),[_vm._v(" No "),_c('v-icon',{attrs:{"color":"red accent-3"}},[_vm._v("mdi-cancel")])],1)]}}],null,false,866487431)},[_c('span',[_vm._v("Rechazar")])]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":""},on:{"click":function($event){return _vm.dialog.open(_vm.item, 'approve')}}},'v-btn',attrs,false),on),[_vm._v(" Si "),_c('v-icon',{attrs:{"color":"light-green darken-1"}},[_vm._v("mdi-check-circle-outline")])],1)]}}],null,false,3258438206)},[_c('span',[_vm._v("Aceptar")])])],1)],1):_c('div',{staticClass:"d-flex justify-center mr-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"rounded":true},on:{"click":function($event){_vm.dialog.open(_vm.item, _vm.status(_vm.item))}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.statusColor}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }