import firebase from 'firebase/app'
import 'firebase/functions'

export async function sendWebPushToken(token) {
  const sendToken = firebase
    .functions()
    .httpsCallable('msg-AddUserPushTokenIndex')
  const data = { t: token, os: 'web' }
  try {
    await sendToken(data)
  } catch (error) {
    console.log('Error updating the push token', error)
  }
}
