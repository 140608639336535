<template>
  <v-card :color="statusColor.card">
    <v-container class="pa-0">
      <v-row dense>
        <v-col cols="5"
          ><v-icon :size="icon.size" class="mr-1" :color="statusColor.icon">
            {{ icon.symbol }}
          </v-icon></v-col
        >
        <v-col cols="7">
          <v-card-title class="headline" :class="textColor">
            {{ name }}
          </v-card-title>
          <v-card-subtitle class="text-subtitle-2" :class="textColor">
            <p>{{ areaInStage(stage) }}</p>
            <span class="caption">{{ effective }}</span>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text class="text-body-1" :class="textColor"
      >{{ stage.comment }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusIcon } from '@/components/requests/statusModel'
import { formattedDateTime } from '@/micro/time/date'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    statusOf: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters('assignments', ['areaInStage']),
    ...mapGetters('workflows', ['statusColorByStage']),
    icon() {
      return this.statusIcon(this.stage.id, this.stage.status)
    },
    statusColor() {
      return this.statusColorByStage(
        this.stage,
        this.statusOf(this.stage, this.editedItem),
        this.editedItem
      )
    },
    textColor() {
      const parts = this.statusColor.icon.split(' ')
      return `${parts[0]}--text text--${parts[1]}`
    },
    effective() {
      return formattedDateTime(this.stage.effective)
    },
    name() {
      const checkin = {
        request: 'Ingresé',
        auth: 'Ingresó'
      }
      const checkout = {
        request: 'Salí',
        auth: 'Salió'
      }
      return this.stage.id === 'checkin'
        ? checkin[this.type]
        : checkout[this.type]
    }
  },
  methods: {
    statusIcon
  }
}
</script>
