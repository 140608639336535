<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    :transition="dialogTransition"
    v-model="dialog"
    max-width="500px"
  >
    <template>
      <v-card height="100%">
        <v-toolbar color="transparent">
          <v-toolbar-title class="identity--text">{{
            toolbarTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fill-height fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="12" class="text-center">
              <div class="d-block">
                {{ item.party.name }} @ {{ scheduleCheck }}
              </div>
              <v-avatar class="ma-3 outlined" size="328">
                <!-- <v-img :src="passImg(stage.resource.pass)"></v-img> -->
                <v-img :src="stage.resource.pass"></v-img>
              </v-avatar>
              <div class="d-block text-h2 spacing">
                {{ stage.resource.code }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { isPhone } from '@/micro/display'
import { formattedDateTime } from '@/micro/time/date'
export default {
  data: () => ({
    dialog: false
  }),

  props: {
    item: {
      type: Object,
      required: true
    },
    stage: {
      type: Object,
      required: true
    },
    passImg: {
      type: Function,
      required: true
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  computed: {
    isPhone() {
      return isPhone(this.$vuetify)
    },
    dialogTransition() {
      return this.isPhone ? 'dialog-bottom-transition' : ''
    },
    toolbarTitle() {
      return 'Pase de entrada'
    },
    scheduleCheck() {
      return formattedDateTime(
        this.stage.id === 'pass1'
          ? this.item.resource.schedule_start
          : this.item.resource.schedule_end
      )
    }
  },

  methods: {
    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
.v-application .text-h2.spacing {
  letter-spacing: 0.2em !important;
}
</style>
